import qbiticonIcon from '../images/qbiticon.png';
// import binanceCoin from '../images/binance-coin.png'


export const supportNetwork = { 
    // 7622 : {
    //     name : "Qbit Chain",
    //     chainId : 7622,
    //     rpc : "https://testnet-rpc.qbitscan.com/",
    //     image : qbiticonIcon,
    //     symbol : 'KNB'
    // },
    13600 : {
        name : "Krono Chain",
        chainId : 13600,
        rpc : "https://mainnet-rpc.qbitscan.com/",
        image : qbiticonIcon,
        symbol : 'KNB'
    },
    // 97 : {
    //     name : "Binanace Testnet",
    //     chainId : 97,
    //     rpc : "https://data-seed-prebsc-1-s1.binance.org:8545/",
    //     image : binanceCoin,
    //     symbol : 'BNB'
    // },
    'default':{
        name : "Krono Chain",
        chainId : 13600,
        rpc : "https://mainnet-rpc.qbitscan.com/",
        image : qbiticonIcon,
        symbol : 'KNB'
    }

}