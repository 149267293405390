import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import poolFactoryAbi from '../json/poolfactory.json';
import poolManagerAbi from '../json/poolManager.json';
import { toast } from "react-toastify";
import { contract } from "./constant";
import { useHistory } from "react-router-dom";
import { MulticallContractWeb3 } from "./useContracts";
import { getWeb3 } from "./connectors";


export const useAdminStats = (updater) => {
    const context = useWeb3React();
    const { chainId } = context;
    let history = useHistory();

    let web3 = getWeb3(chainId);
    let poolFactoryAddress = contract[chainId] ? contract[chainId].poolfactory : contract['default'].poolfactory
    let poolManagerAddress = contract[chainId] ? contract[chainId].poolmanager : contract['default'].poolmanager


    const [stats, setStats] = useState({
        IsEnabled: false,
        adminWallet: '',
        contributeWithdrawFee: 0,
        presalePrice: 0,
        privatePrice: 0,
        fairPrice: 0,
        owner: '',
        mowner : ''
    });

    const mc = MulticallContractWeb3(chainId);
    let pmc = new web3.eth.Contract(poolFactoryAbi, poolFactoryAddress);
    let mmc = new web3.eth.Contract(poolManagerAbi, poolManagerAddress);


    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await mc.aggregate([
                    pmc.methods.IsEnabled(), //0
                    pmc.methods.adminWallet(), //1
                    pmc.methods.contributeWithdrawFee(), //2
                    pmc.methods.presalePrice(), //3
                    pmc.methods.privatePrice(), //4
                    pmc.methods.fairPrice(), //5
                    pmc.methods.owner(), //6
                    mmc.methods.owner(), //7
                ]);

                setStats({
                    IsEnabled: data[0],
                    adminWallet: data[1],
                    contributeWithdrawFee: data[2] / 100,
                    presalePrice: data[3] / Math.pow(10, 18),
                    privatePrice: data[4] / Math.pow(10, 18),
                    fairPrice: data[5] / Math.pow(10, 18),
                    owner: data[6],
                    mowner : data[7]
                })
            }
            catch (err) {
                toast.error(err.reason ? err.reason : err.message);
                history.push('/sale-list');
            }
        }

        if (mc) {
            fetch();
        }
        else {
            setStats({
                IsEnabled: false,
                adminWallet: '',
                contributeWithdrawFee: 0,
                presalePrice: 0,
                privatePrice: 0,
                fairPrice: 0,
                owner: '',
                mowner : ''
            })
        }
        // eslint-disable-next-line
    }, [updater, chainId]);

    return stats;
}


