import React, { useState } from 'react';
// import { toast } from 'react-toastify';
import HashLoader from "react-spinners/HashLoader";
import { useWeb3React } from "@web3-react/core";
import { Link } from 'react-router-dom';
import { useCommonKnbStats } from './helper/useStats';
import { formatPrice } from '../../hooks/contractHelper';
import { trimAddress } from '../../hooks/constant';
import { supportNetwork } from '../../hooks/network';



export default function KnbLockList() {
    const [updater, setUpdater] = useState({ page: 0, pageSize: 10, loading: true });
    const stats = useCommonKnbStats(updater);
    const context = useWeb3React();
    const { chainId } = context;

    return (
        <React.Fragment>
            <section className="explore-area prev-project-area">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8 col-lg-7">
                            <div class="intro text-center">
                                <div class="intro-content">
                                    <span className="intro-text">Lock</span>
                                    <h3 className="mt-3 mb-0">KNB Lock List</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center text-center">
                    <div class="col-12">

                        <div class="explore-menu btn-group btn-group-toggle flex-wrap justify-content-center text-center mb-md-4" data-toggle="buttons">
                            <label class="btn active d-table text-uppercase p-2">
                                <Link to="/knb-locked" class="explore-btn">
                                    <span>KNB Lock List</span>
                                </Link>
                            </label>
                            <label class="btn d-table text-uppercase p-2">
                                <Link to="/my-knb-lock" class="explore-btn text-white">
                                    <span>My Lock</span>
                                </Link>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center">
                    <div class="col-12">
                        <section class="leaderboard-area">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="table-responsive">
                                            <table class="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">No</th>
                                                        <th scope="col">User Address</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {stats.loading ? (
                                                        <div className='row'>
                                                            <div className='col-12 d-flex justify-content-between'>
                                                                <HashLoader size="100" color="#fff" cssOverride={{ "left": "50%", "top": "20%" }} />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        stats.tokenList.length > 0 ? (
                                                            stats.tokenList.slice(0).reverse().map((rowdata, index) => {
                                                                
                                                                return (
                                                                    <tr>
                                                                        <td>{stats.page > 0 ? stats.page * stats.pageSize + 1 + index : index + 1}</td>
                                                                        <td> <a href={`https://explorer.qbitscan.com/address/${rowdata.knb}`}>{trimAddress(rowdata.knb)}</a></td>
                                                                        <td>{rowdata.amount ? formatPrice(rowdata.amount / Math.pow(10, rowdata.decimals)) : "0"} {rowdata.symbol}</td>
                                                                        <td><Link style={{ "color": "#47c8f2" }} to={`/lock-details/${rowdata.knb}${chainId ? `?chainid=${chainId}` : ''}`}>View</Link></td>

                                                                    </tr>
                                                                )
                                                            })
                                                        ) : (
                                                            <tr className='text-center mt-4'>
                                                                <td style={{"backgroundColor" : "transparent"}}>No Record Found</td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <nav>
                                            <ul class="page-numbers">
                                                <li>
                                                    {stats.page > 0 &&
                                                        <a href="#sec" onClick={(e) => setUpdater({ page: stats.page - 1, pageSize: stats.pageSize, loading: true })}>
                                                            <i className="icon-Vector mr-2"></i>Previous
                                                        </a>
                                                    }
                                                </li>
                                                {Math.floor(stats.allNormalTokenLockedCount / stats.pageSize) > parseFloat(stats.page) &&
                                                    < li >
                                                        <a href="#sec" onClick={(e) => setUpdater({ page: stats.page + 1, pageSize: stats.pageSize, loading: true })}>
                                                            Next<i className="icon-arrow_right ml-2"></i>
                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
