import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supportNetwork } from '../../../hooks/network';
import HashLoader from "react-spinners/HashLoader";
import { formatPrice } from '../../../hooks/contractHelper';
import { usePoolListStats } from './helper/useStats';



export default function ProjectList() {
    const [updater, setUpdater] = useState({ page: 0, pageSize: 6, loading: true });
    const stats = usePoolListStats(updater);
   

    return (
        <React.Fragment>

            <section class="project-area explore-area">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8 col-lg-7">
                            <div class="intro text-center">
                                <div class="intro-content">
                                    <span class="intro-text">Launchpad List</span>
                                    <h3 class="mt-3 mb-0">Multi-chain Launchpad</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center">
                    <div class="col-12">

                        <div class="explore-menu btn-group btn-group-toggle flex-wrap justify-content-center text-center mb-md-4" data-toggle="buttons">
                            <label class="btn active d-table text-uppercase p-2">
                                <Link to="/sale-list" class="explore-btn">
                                    <span>All</span>
                                </Link>
                            </label>
                            <label class="btn d-table text-uppercase p-2">
                                <Link to="/my-contribution" class="explore-btn text-white">
                                    <span>My Contribution</span>
                                </Link>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row items">
                    <React.Fragment>
                        {
                            stats.loading ? (
                                <div className='col-md-12'>
                                    <HashLoader size="100" color="#fff" cssOverride={{ "left": "50%", "textAlign": "center", "top": "50%" }} />

                                </div>
                            ) : (
                                stats.poolList.length > 0 ? (
                                    stats.poolList.slice(0).reverse().map((rowdata, index) => {
                                        let chainSymbol = supportNetwork[stats.chainId] ? supportNetwork[stats.chainId].image : supportNetwork['default'].image
                                        let currencySymbol = supportNetwork[stats.chainId] ? supportNetwork[stats.chainId].symbol : supportNetwork['default'].symbol

                                        return (
                                            <div class="col-12 col-md-4 col-lg-4 item explore-item" key={index}>
                                                <div class="card project-card">
                                                    <div class="media">
                                                        <Link to={rowdata.poolType === '0' ? `/presale-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '1' ? `/private-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '2' ? `/fairlaunch-details/${rowdata.poolAddress}chainid=${stats.chainId}` : '/'}>
                                                            <img class="card-img-top avatar-max-lg" src={rowdata.logourl ? rowdata.logourl : ""} alt="logo-url" />
                                                        </Link>
                                                        <div class="media-body ml-4">
                                                            <Link to={rowdata.poolType === '0' ? `/presale-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '1' ? `/private-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '2' ? `/fairlaunch-details/${rowdata.poolAddress}??chainid=${stats.chainId}` : '/'}>
                                                                <h4 class="m-0">{rowdata.name ? rowdata.name : ''}</h4>
                                                            </Link>
                                                            <div class="countdown-times">
                                                                <div className='d-flex justify-content-center'>
                                                                    <span className='mr-3'>
                                                                        {
                                                                            rowdata.poolState === '1' ? <h4 class="tag-btn text-uppercase text-center bg-green">Completed</h4> :
                                                                                rowdata.poolState === '2' ? <h4 class="tag-btn text-uppercase text-center bg-red">Canceled</h4> :
                                                                                    parseInt(rowdata.endTime) < Math.floor(new Date().getTime() / 1000.0) ? <h4 class="tag-btn text-uppercase text-center bg-red">Ended</h4> :
                                                                                        parseInt(rowdata.startTime) > Math.floor(new Date().getTime() / 1000.0) ? <h4 class="tag-btn text-uppercase text-center bg-yellow">Incoming</h4> :
                                                                                            parseInt(rowdata.startTime) < Math.floor(new Date().getTime() / 1000.0) && parseInt(rowdata.endTime) > Math.floor(new Date().getTime() / 1000.0) ? <h4 class="tag-btn text-uppercase text-center bg-green">Ongoing</h4> : ''
                                                                        }
                                                                    </span>
                                                                    <span>

                                                                        {
                                                                            rowdata.poolType === '0' ? <h4 class="tag-btn text-uppercase text-center bg-pink">Presale</h4> :
                                                                                rowdata.poolType === '1' ? <h4 class="tag-btn text-uppercase text-center bg-yellow">PrivateSale</h4> :
                                                                                    rowdata.poolType === '2' ? <h4 class="tag-btn text-uppercase text-center bg-purple">Fair Launch</h4> : ''

                                                                        }
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <div class="items">

                                                            <div class="single-item">
                                                                <span>Total raise</span>
                                                                <span className='ml-1'>{rowdata.totalRaised ? formatPrice(rowdata.totalRaised) : 0} {currencySymbol}</span>
                                                            </div>
                                                            {rowdata.poolType !== '2' &&
                                                                <>
                                                                    <div class="single-item ml-3">
                                                                        <span>Min Alloc.</span>
                                                                        <span className='ml-1'>{formatPrice(rowdata.minContribution)} {currencySymbol}</span>
                                                                    </div>

                                                                    <div class="single-item">
                                                                        <span>Max Alloc.</span>
                                                                        <span className='ml-1'>{formatPrice(rowdata.maxContribution)} {currencySymbol}</span>
                                                                    </div>
                                                                </>
                                                            }
                                                            <div class="single-item ml-3">
                                                                <span>Softcap</span>
                                                                <span className='ml-1'>{formatPrice(rowdata.softCap)} {currencySymbol}</span>
                                                            </div>
                                                        </div>
                                                        <div class="item-progress">
                                                            <div className='mt-3 mt-md-3'>Progress ~ ({parseFloat(rowdata.percentageRaise).toFixed(2)}%)</div>
                                                            <div class="progress mt-2 mt-md-2 ">
                                                                <div class="progress-bar" role="progressbar" style={{ "width": `${parseFloat(rowdata.percentageRaise).toFixed(2)}%` }} aria-valuenow={parseFloat(rowdata.percentageRaise).toFixed(2)} aria-valuemin="0" aria-valuemax="100">{parseFloat(rowdata.percentageRaise).toFixed(2)}%</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="project-footer d-flex align-items-center mt-3 mt-md-3">
                                                        <Link to={rowdata.poolType === '0' ? `/presale-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '1' ? `/private-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '2' ? `/fairlaunch-details/${rowdata.poolAddress}??chainid=${stats.chainId}` : '/'} class="btn btn-bordered-white btn-smaller">
                                                            view pool
                                                        </Link>

                                                        <div class="social-share ml-auto">
                                                            <ul class="d-flex list-unstyled">
                                                                <li>
                                                                    {(rowdata.poolType === '0' || rowdata.poolType === '1') &&
                                                                        <>
                                                                            1 {currencySymbol} ~ {formatPrice(rowdata.rate)} {rowdata.symbol}
                                                                        </>
                                                                    }
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="blockchain-icon">
                                                        <img src={chainSymbol} width="40px" height="40px" alt="ChainImage12" />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div class="col-12 item explore-item mt-5">
                                        <p className='text-center'>No Record Found</p>
                                    </div>
                                )
                            )
                        }
                    </React.Fragment >

                </div>
                <div class="row mt-4 mt-md-5">
                    <div class="col-12">
                        <nav>
                            <ul class="page-numbers">
                                {stats.page > 0 &&
                                    <li>
                                        <a class="next page-numbers" href="#sec" onClick={(e) => setUpdater({ page: stats.page - 1, pageSize: stats.pageSize, loading: true })}>
                                            <i class="icon-arrow-left"></i>Previous
                                        </a>
                                    </li>
                                }
                                {Math.floor(stats.getTotalNumberOfPools / stats.pageSize) > stats.page &&
                                    <li>
                                        <a class="next page-numbers" href="#sec" onClick={(e) => setUpdater({ page: stats.page + 1, pageSize: stats.pageSize, loading: true })}>Next
                                            <i class="icon-arrow-right"></i>
                                        </a>
                                    </li>
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>

        </React.Fragment >
    )
}
