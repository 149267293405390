import React from 'react';
import '../404.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function NotFoundPage() {
    return (
        <div className="main">
            <section className="hero-section">
                <div className='container'>
                    <div class="section">
                        <h1 class="error">404</h1>
                        <div class="page text-white">Ooops!!! The page you are looking for is not found</div>
                        <Link to="/" class="back-home text-white" href="#!">Back to home</Link>
                    </div>
                </div>

            </section>
        </div>
    )
}
