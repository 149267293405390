import React from 'react'
// import { Link } from 'react-router-dom';
import footerlogo from '../images/logo.png'
import { FOOTER_SOCIAL } from '../hooks/constant'

export default function Footer() {
    return (
        <>
            <footer className="footer-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 text-center">

                            <div className="footer-items">

                                <a className="navbar-brand" href="#sec">
                                    <img src={footerlogo} className='footer-logo' alt="footer-logo" />
                                </a>
                                <p>Contact us at: admin@kronobit.org</p>
                                <div className="social-icons d-flex justify-content-center my-4">
                                    
                                    {FOOTER_SOCIAL && FOOTER_SOCIAL.map((item, key) => {
                                        return (
                                            <a key={key} className="facebook" href={item.link} target="_blank" rel="noreferrer">
                                                <i className={item.icon}></i>
                                                <i className={item.icon}></i>
                                            </a>
                                        )
                                    })}
                                </div>
                                <ul className="list-inline">
                                    <li className="list-inline-item"><a target='_blank' rel="noreferrer" href="https://kronobit.org/intro.html">Concept</a></li>
                                    <li className="list-inline-item"><a target='_blank' rel="noreferrer" href="https://kronobit.org/chainpaper.pdf" >Whitepaper</a></li>
                                    <li className="list-inline-item"><a target='_blank' rel="noreferrer" href="https://t.me/KronobitNetworks_news">Community</a></li>
                                </ul>
                                
                                <div className="copyright-area py-4">&copy;2023 Kronobit launchpad . All Rights Reserved</div>
                            </div>

                            <div id="scroll-to-top" className="scroll-to-top">
                                <a href="#header" className="smooth-anchor">
                                    <i className="fa-solid fa-arrow-up"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
