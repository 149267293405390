import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { supportNetwork } from '../hooks/network';
import HashLoader from "react-spinners/HashLoader";
import { formatPrice } from '../hooks/contractHelper';
import { usePoolListStats } from './launchpadApply/SaleList/helper/useStats';
import cta_thumbImg from '../images/cta_thumb.png';
import qbitIcon from '../images/qbit-icon.png';
import { exchange_partneters } from '../hooks/constant';

export default function Home() {
    const [updater] = useState({ page: 0, pageSize: 6, loading: true });
    const stats = usePoolListStats(updater);


    return (
        <React.Fragment>
            <div className="main">
                <section className="hero-section">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-6 col-lg-9 text-center">

                                <div className="hero-content">
                                    <div className="intro text-center mb-5">
                                        <span className="intro-text">Kronobit</span>
                                        <h1 className="mt-4">The Next Generation Web 3.0 Launchpad</h1>
                                        {/* <p>Full-scale Blockchain Gaming Ecosystem for Launchpads &amp; NFT Pre-Sales</p> */}
                                    </div>

                                    <div className="button-group">
                                        <Link className="btn btn-bordered active smooth-anchor" to="/sale-list"><i className="icon-rocket mr-2"></i>Explore Launchpads</Link>
                                        <Link className="btn btn-bordered-white" to="/presale"><i className="icon-note mr-2"></i>Create</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="explore" className="project-area" style={{ "padding": "0px 0px 70px 0px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <div className="intro d-flex justify-content-between align-items-end m-0">
                                    <div className="intro-content">
                                        <span className="intro-text">Launchpads</span>
                                        <h3 className="mt-3 mb-0">Launchpads List</h3>
                                    </div>
                                    <div className="intro-btn">
                                        <Link to="/sale-list" className="btn content-btn">View More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row items">
                            <React.Fragment>
                                {
                                    stats.loading ? (
                                        <div className='col-md-12'>
                                            <HashLoader size="100" color="#fff" cssOverride={{ "left": "50%", "textAlign": "center", "top": "50%" }} />

                                        </div>
                                    ) : (
                                        stats.poolList.length > 0 ? (
                                            stats.poolList.slice(0).reverse().map((rowdata, index) => {
                                                let chainSymbol = supportNetwork[stats.chainId] ? supportNetwork[stats.chainId].image : supportNetwork['default'].image
                                                let currencySymbol = supportNetwork[stats.chainId] ? supportNetwork[stats.chainId].symbol : supportNetwork['default'].symbol
                                                return (
                                                    <div class="col-12 col-md-6 col-lg-6 item explore-item" key={index}>
                                                        <div class="card project-card">


                                                            <div class="media">
                                                                <Link to={rowdata.poolType === '0' ? `/presale-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '1' ? `/private-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '2' ? `/fairlaunch-details/${rowdata.poolAddress}??chainid=${stats.chainId}` : '/'}>
                                                                    <img class="card-img-top avatar-max-lg" src={rowdata.logourl ? rowdata.logourl : ""} alt="logo-url" />
                                                                </Link>
                                                                <div class="media-body ml-4">
                                                                    <Link to={rowdata.poolType === '0' ? `/presale-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '1' ? `/private-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '2' ? `/fairlaunch-details/${rowdata.poolAddress}??chainid=${stats.chainId}` : '/'}>
                                                                        <h4 class="m-0">{rowdata.name ? rowdata.name : ''}</h4>
                                                                    </Link>
                                                                    <div class="countdown-times">
                                                                        <div className='d-flex justify-content-center'>
                                                                            <span className='mr-3'>
                                                                                {
                                                                                    rowdata.poolState === '1' ? <h4 class="tag-btn text-uppercase text-center bg-green">Completed</h4> :
                                                                                        rowdata.poolState === '2' ? <h4 class="tag-btn text-uppercase text-center bg-red">Canceled</h4> :
                                                                                            parseInt(rowdata.endTime) < Math.floor(new Date().getTime() / 1000.0) ? <h4 class="tag-btn text-uppercase text-center bg-red">Ended</h4> :
                                                                                                parseInt(rowdata.startTime) > Math.floor(new Date().getTime() / 1000.0) ? <h4 class="tag-btn text-uppercase text-center bg-yellow">Incoming</h4> :
                                                                                                    parseInt(rowdata.startTime) < Math.floor(new Date().getTime() / 1000.0) && parseInt(rowdata.endTime) > Math.floor(new Date().getTime() / 1000.0) ? <h4 class="tag-btn text-uppercase text-center bg-green">Ongoing</h4> : ''
                                                                                }
                                                                            </span>
                                                                            <span>

                                                                                {
                                                                                    rowdata.poolType === '0' ? <h4 class="tag-btn text-uppercase text-center bg-pink">Presale</h4> :
                                                                                        rowdata.poolType === '1' ? <h4 class="tag-btn text-uppercase text-center bg-yellow">PrivateSale</h4> :
                                                                                            rowdata.poolType === '2' ? <h4 class="tag-btn text-uppercase text-center bg-purple">Fair Launch</h4> : ''

                                                                                }
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="card-body">
                                                                <div class="items">

                                                                    <div class="single-item">
                                                                        <span>Total raise</span>
                                                                        <span className='ml-1'>{rowdata.totalRaised ? formatPrice(rowdata.totalRaised) : 0} {currencySymbol}</span>
                                                                    </div>

                                                                    <div class="single-item ml-3">
                                                                        <span>Min Alloc.</span>
                                                                        <span className='ml-1'>{formatPrice(rowdata.minContribution)} {currencySymbol}</span>
                                                                    </div>

                                                                    <div class="single-item">
                                                                        <span>Max Alloc.</span>
                                                                        <span className='ml-1'>{formatPrice(rowdata.maxContribution)} {currencySymbol}</span>
                                                                    </div>
                                                                    <div class="single-item ml-3">
                                                                        <span>Softcap</span>
                                                                        <span className='ml-1'>{formatPrice(rowdata.softCap)} {currencySymbol}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="item-progress">
                                                                    <div className='mt-3 mt-md-3'>Progress ~ ({parseFloat(rowdata.percentageRaise).toFixed(2)}%)</div>
                                                                    <div class="progress mt-2 mt-md-2 ">
                                                                        <div class="progress-bar" role="progressbar" style={{ "width": `${parseFloat(rowdata.percentageRaise).toFixed(2)}%` }} aria-valuenow={parseFloat(rowdata.percentageRaise).toFixed(2)} aria-valuemin="0" aria-valuemax="100">{parseFloat(rowdata.percentageRaise).toFixed(2)}%</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="project-footer d-flex align-items-center mt-3 mt-md-3">
                                                                <Link to={rowdata.poolType === '0' ? `/presale-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '1' ? `/private-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '2' ? `/fairlaunch-details/${rowdata.poolAddress}??chainid=${stats.chainId}` : '/'} class="btn btn-bordered-white btn-smaller">
                                                                    view pool
                                                                </Link>

                                                                <div class="social-share ml-auto">
                                                                    <ul class="d-flex list-unstyled">
                                                                        <li>
                                                                            {(rowdata.poolType === '0' || rowdata.poolType === '1') &&
                                                                                <>
                                                                                    1 {currencySymbol} ~ {formatPrice(rowdata.rate)} {rowdata.symbol}
                                                                                </>
                                                                            }
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div class="blockchain-icon">
                                                                <img src={chainSymbol} width="40px" height="40px" alt="ChainImage12" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div class="col-12 item explore-item mt-5">
                                                <p className='text-center'>No Record Found</p>
                                            </div>
                                        )
                                    )
                                }
                            </React.Fragment >

                        </div>
                    </div>
                </section>

                <section className="content-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <div className="content intro">
                                    <span className="intro-text">launchpads</span>
                                    <h2>Build on Kronobit Networks</h2>
                                    <p>Join Launchpads on Kronobit blockchains in a single click. Support game by providing LP or just stake the game and get rewards.</p>
                                    <ul className="list-unstyled items mt-5">

                                        <li>
                                            <div className="content-list d-flex align-items-center">
                                                <div className="content-icon">
                                                    <span>
                                                        <i className="fas text-success fa-2x fa-check"></i>
                                                    </span>
                                                </div>
                                                <div className="content-body ml-4">
                                                    <h3 className="m-0">Metamask</h3>
                                                    <p className="mt-3">Start building on Kronobit Networks using the tools you are already familiar with MetaMask.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="item">

                                            <div className="content-list d-flex align-items-center">
                                                <div className="content-icon">
                                                    <span>
                                                        <i className="fas text-success fa-2x fa-check"></i>
                                                    </span>
                                                </div>
                                                <div className="content-body ml-4">
                                                    <h3 className="m-0">EVM to Dapps</h3>
                                                    <p className="mt-3">Kronobit Networks supports the EVM and you can deploy dApps just like on Ethereum.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="item">

                                            <div className="content-list d-flex align-items-center">
                                                <div className="content-icon">
                                                    <span>
                                                        <i className="fas text-success fa-2x fa-check"></i>
                                                    </span>
                                                </div>
                                                <div className="content-body ml-4">
                                                    <h3 className="m-0">Documentation</h3>
                                                    <p className="mt-3">Access to the documentation and connect with our developer community.</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">

                                <img src={qbitIcon} className="multipad-img" alt="qbiticon" />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="explore" className="project-area team-area" style={{ "padding": "0px 0px 70px 0px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <div className="intro d-flex justify-content-between align-items-end m-0">
                                    <div className="intro-content">
                                        <h3 className="mt-3 mb-0">EXCHANGES & PARTNERS</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style={{ "paddingTop": "30px" }}>
                            {exchange_partneters && exchange_partneters.length > 0 && exchange_partneters.map((item,key) => {
                                return (
                                    <div class="col-12 col-md-3 col-lg-3 hover-effect-team" key={key}>
                                        {/* <div className="card team-card text-center"> */}
                                            <a target='_blank' rel="noreferrer"  className="d-inline-block" href={item.link}>
                                                <img className="mx-auto" src={item.img} alt="partner" />
                                            </a>

                                            {/* <div className="team-content mt-3">

                                                <span className="d-inline-block mt-2 mb-3">CEO</span>

                                                <div className="social-share">
                                                    <ul className="d-flex justify-content-center list-unstyled">
                                                        <li>
                                                            <a href="#sec">
                                                                <i className="icon-social-facebook"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#sec">
                                                                <i className="icon-social-twitter"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#sec">
                                                                <i className="icon-social-linkedin"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#sec">
                                                                <i className="icon-social-reddit"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                        {/* </div> */}
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </section>

                <section className="cta-area p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 card">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12 col-md-5 text-center">
                                        <img src={cta_thumbImg} alt="" />
                                    </div>
                                    <div className="col-12 col-md-6 mt-4 mt-md-0">
                                        <h2 className="m-0">Apply for Launchpad</h2>
                                        <p>Get access to huge set of tools to seamlessly handle your game's integration with blockchain.</p>
                                        <Link className="btn btn-bordered active d-inline-block" to="/presale"><i className="icon-rocket mr-2"></i>Create</Link>
                                    </div>
                                </div>
                                {/* <a className="cta-link" href="apply.html"></a> */}
                            </div>
                        </div>
                    </div>
                </section>

                <div id="search" className="modal fade p-0">
                    <div className="modal-dialog dialog-animated">
                        <div className="modal-content h-100">
                            <div className="modal-header" data-dismiss="modal">
                                Search <i className="far fa-times-circle icon-close"></i>
                            </div>
                            <div className="modal-body">
                                <form className="row">
                                    <div className="col-12 align-self-center">
                                        <div className="row">
                                            <div className="col-12 pb-3">
                                                <h2 className="search-title mt-0 mb-3">What are you looking for?</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 input-group mt-md-4">
                                                <input type="text" placeholder="Enter your keywords" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 input-group align-self-center">
                                                <button className="btn btn-bordered-white mt-3">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}
