import React, { useEffect, useState } from 'react'
import { useCommonStats, useAccountStats } from './helper/useStats';
import Countdown, { zeroPad } from "react-countdown";
import { useWeb3React } from "@web3-react/core";
import { supportNetwork } from '../../../hooks/network';
import Button from 'react-bootstrap-button-loader';
import { formatPrice } from '../../../hooks/contractHelper';
import poolAbi from '../../../json/presalePool.json';
import { parseEther } from 'ethers/lib/utils';
import { toast } from 'react-toastify';
import { getWeb3 } from '../../../hooks/connectors';
import { getContract } from '../../../hooks/contractHelper';
import ReactPlayer from 'react-player/youtube'
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import { contract } from '../../../hooks/constant';
import editImg from '../../../images/edit.png';
import iconImg from '../../../images/icon.png';




export default function ProjectDetails() {
    const [updater, setUpdater] = useState(1);
    const stats = useCommonStats(updater);
    const accStats = useAccountStats(updater);
    const [currentTime] = useState(Math.floor(new Date().getTime() / 1000.0));
    const [social, setSocial] = useState({});
    const { chainId, account, library } = useWeb3React();
    const [amount, setAmount] = useState(0);
    const [btndisabled, setBtndisabled] = useState(false);
    const [error_msg, setError_msg] = useState('');
    const [loading, setLoading] = useState(false);
    const [waddloading, setWaddloading] = useState(false);
    const [finalLoading, setFinalLoading] = useState(false);
    const [wcLoading, setWcLoading] = useState(false);
    const [ctLoading, setCtLoading] = useState(false);
    const [locklLoading, setLocklLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [refcopy, setRefcopy] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [unsetmodalShow, setUnsetmodalShow] = useState(false);
    const [editmodalShow, setEditmodalShow] = useState(false);
    const [whitelistAddress, setWhitelistAddress] = useState('');
    const [removeWhitelistAddress, setRemoveWhitelistAddress] = useState('');
    const search = useLocation().search;
    const queryChainId = new URLSearchParams(search).get('chainId');
    const [error, setError] = useState({
        logourl: "",
        website: "",
        facebook: "",
        twitter: "",
        github: "",
        telegram: "",
        instagram: "",
        discord: "",
        reddit: "",
        youtube: "",
        brief: ""
    });

    const [editSocial, setEditSocial] = useState({
        logourl: "",
        website: "",
        facebook: "",
        twitter: "",
        github: "",
        telegram: "",
        instagram: "",
        discord: "",
        reddit: "",
        youtube: "",
        brief: ""
    })

    useEffect(() => {
        function getDetails() {
            let details = stats.poolDetails.toString().split('$#$');
            const object = {
                logourl: details[0],
                website: details[1],
                blockstar: details[2],
                facebook: details[3],
                twitter: details[4],
                github: details[5],
                telegram: details[6],
                instagram: details[7],
                discord: details[8],
                reddit: details[9],
                youtube: details[10],
                brief: details[11]

            }

            setSocial(object);
            setEditSocial(object);
        }
        if (stats) {
            getDetails();
        }
    }, [stats]);

    const checkValidation = (input, inputValue) => {
        let terror = 0;
        let message = "";
        var reg;
        switch (input) {
            case "facebook":
            case "twitter":
            case "github":
            case "telegram":
            case "instagram":
            case "discord":
            case "reddit":
            case "youtube":
            case "blockstart":

                reg = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig);
                if (inputValue !== '' && !reg.test(inputValue)) {
                    terror += 1;
                    message = "Please Enter Valid url!";
                }
                else {
                    message = "";
                }
                break;

            case "logourl":
            case "website":
                reg = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig);
                if (!reg.test(inputValue)) {
                    terror += 1;
                    message = "Please Enter Valid url!";
                }
                else {
                    message = "";
                }
                break;
            default:
                terror += 0;
                break;
        }

        if (terror > 0) {
            setError({ ...error, [input]: message });
            return false;
        }
        else {
            setError({ ...error, [input]: "" });
            return true;
        }
    }

    const checkAllValidation = () => {
        let terror = 0;
        var reg;
        Object.keys(editSocial).map((key, index) => {
            switch (key) {
                case "facebook":
                case "twitter":
                case "github":
                case "telegram":
                case "instagram":
                case "discord":
                case "reddit":
                case "youtube":
                case "blockstart":

                    reg = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig);
                    if (editSocial[key] !== '' && !reg.test(editSocial[key])) {
                        terror += 1;
                    }

                    break;

                case "logourl":
                case "website":
                    reg = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig);
                    if (!reg.test(editSocial[key])) {
                        terror += 1;
                    }

                    break;
                default:
                    terror += 0;
                    break;
            }
            return true;
        });

        if (terror > 0) {
            return false;
        }
        else {
            return true;
        }
    }

    const onChangeInput = (e) => {
        e.preventDefault();
        checkValidation(e.target.name, e.target.value);
        setEditSocial({ ...editSocial, [e.target.name]: e.target.value })
    }

    const handleEditSubmit = async (e) => {
        let check = checkAllValidation();
        if (check) {
            e.preventDefault();
            setEditLoading(true);
            try {

                if (account) {
                    let poolContract = getContract(poolAbi, stats.poolAddress, library);
                    let detailsset = `${editSocial.logourl}$#$${editSocial.website}$#$$#$${editSocial.facebook}$#$${editSocial.twitter}$#$${editSocial.github}$#$${editSocial.telegram}$#$${editSocial.instagram}$#$${editSocial.discord}$#$${editSocial.reddit}$#$${editSocial.youtube}$#$${editSocial.brief}`;
                    let tx = await poolContract.updatePoolDetails(detailsset, {
                        'from': account,
                    });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success');
                                setUpdater(new Date());
                                setEditLoading(false);
                                setEditmodalShow(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setEditLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setEditLoading(false);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please Connect to wallet !');
                    setEditLoading(false);
                }

            }
            catch (err) {
                toast.error(err.reason ? err.reason : err.message);
                setEditLoading(false);
            }
        }
        else {
            toast.error('Required all field or Enter wrong value  ! please check again');
        }

    }


    const countdownrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (

                <ul>
                    <li><span id="days">00</span>Days</li>
                    <li><span id="hours">00</span>Hours</li>
                    <li><span id="minutes">00</span>Minutes</li>
                    <li><span id="seconds">00</span>Seconds</li>
                </ul>

            );
        } else {
            // Render a countdown
            return (
                <ul>
                    <li><span id="days">{zeroPad(days, 2)} </span>Days</li>
                    <li><span id="hours">{zeroPad(hours, 2)}</span>Hours</li>
                    <li><span id="minutes">{zeroPad(minutes, 2)}</span>Minutes</li>
                    <li><span id="seconds">{zeroPad(seconds, 2)}</span>Seconds</li>
                </ul>
            );
        }
    };

    const handleChangeAmount = (e) => {
        setAmount(e.target.value);
        setBtndisabled(true);

        if (isNaN(e.target.value)) {
            setError_msg('please enter valid amount');
            setBtndisabled(true);
        }

        else if (parseFloat(e.target.value) === 0 || e.target.value === '') {
            setError_msg('amount must be greater than zero');
            setBtndisabled(true);
        }
        else if (parseFloat(e.target.value) < parseFloat(stats.minContribution) || parseFloat(e.target.value) > parseFloat(stats.maxContribution)) {
            setError_msg(`amount must be between ${formatPrice(stats.minContribution)} and ${formatPrice(stats.maxContribution)}`);
            setBtndisabled(true);
        }
        else {
            setError_msg('');
            setBtndisabled(false);
        }
        return;
    }


    const handleMaxAmount = (e) => {
        e.preventDefault();
        let maxamount = parseFloat(accStats.balance) - parseFloat(0.01);
        if (parseFloat(maxamount) < parseFloat(stats.minContribution) || parseFloat(maxamount) > parseFloat(stats.maxContribution)) {
            setError_msg(`amount must be between ${stats.minContribution} and ${stats.maxContribution}`);
            setBtndisabled(true);
        }
        setAmount(maxamount.toFixed(4).toString());
    }

    const handleSubmitContribution = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (amount > 0 && (parseFloat(stats.maxContribution) > parseFloat(amount) || parseFloat(stats.minContribution) < parseFloat(amount))) {
                if (account) {
                    if (chainId) {
                        if (parseFloat(accStats.balance) >= parseFloat(amount)) {

                            let poolContract = getContract(poolAbi, stats.poolAddress, library);

                            let tx = await poolContract.contribute({
                                'from': account,
                                value: parseEther(amount)
                            });
                            const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                            toast.promise(
                                resolveAfter3Sec,
                                {
                                    pending: 'Waiting for confirmation 👌',
                                }
                            )

                            var interval = setInterval(async function () {
                                let web3 = getWeb3(chainId);
                                var response = await web3.eth.getTransactionReceipt(tx.hash);
                                if (response != null) {
                                    clearInterval(interval)
                                    if (response.status === true) {
                                        toast.success('success ! your last transaction is success 👍');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else if (response.status === false) {
                                        toast.error('error ! Your last transaction is failed.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else {
                                        toast.error('error ! something went wrong.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                }
                            }, 5000);

                        }
                        else {
                            toast.error('you don\'t have enough balance !');
                            setLoading(false);
                        }

                    }
                    else {
                        toast.error('Please select Smart Chain Network !');
                        setLoading(false);
                    }
                }
                else {
                    toast.error('Please Connect Wallet!');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Amount !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    const handleWhitelistStatus = async (e) => {
        e.preventDefault();
        console.log(e.target.value)
        // try {
        if (account) {
            if (chainId) {
                let whitelist_status = e.target.value === '1' ? true : false;

                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.setWhitelisting(whitelist_status, {
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation 👌',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success 👍');
                            setUpdater(new Date());
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please select Smart Chain Network !');

            }
        }
        else {
            toast.error('Please Connect Wallet!');

        }

        // }
        // catch (err) {
        //     toast.error(err.reason);

        // }
    }

    const handleSetWhitelist = async (e) => {
        e.preventDefault();
        setWaddloading(true);
        try {
            const waddress = whitelistAddress.split(/\r?\n/);
            if (waddress.length > 0) {
                if (account) {
                    let poolContract = getContract(poolAbi, stats.poolAddress, library);

                    let tx = await poolContract.addWhitelistedUsers(waddress, {
                        'from': account,
                    });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please Connect to wallet !');
                    setWaddloading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Addess !');
                setWaddloading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setWaddloading(false);
        }
    }

    const handleRemoveWhitelist = async (e) => {
        e.preventDefault();
        setWaddloading(true);
        try {
            const waddress = removeWhitelistAddress.split(/\r?\n/);
            if (waddress.length > 0) {
                if (account) {
                    let poolContract = getContract(poolAbi, stats.poolAddress, library);

                    let tx = await poolContract.removeWhitelistedUsers(waddress, {
                        'from': account,
                    });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please Connect to wallet !');
                    setWaddloading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Addess !');
                setWaddloading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setWaddloading(false);
        }
    }

    const handleFinalize = async (e) => {
        e.preventDefault();
        setFinalLoading(true);
        try {

            if (account) {
                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.finalize({
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success');
                            setUpdater(new Date());
                            setFinalLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                            setFinalLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                            setFinalLoading(false);
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please Connect to wallet !');
                setFinalLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setFinalLoading(false);
        }
    }

    const handleWithdrawContribution = async (e) => {
        e.preventDefault();
        setWcLoading(true);
        try {

            if (account) {
                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.withdrawContribution({
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success');
                            setUpdater(new Date());
                            setWcLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                            setWcLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                            setWcLoading(false);
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please Connect to wallet !');
                setWcLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setWcLoading(false);
        }
    }

    const handleClaimToken = async (e) => {
        e.preventDefault();
        setCtLoading(true);
        try {

            if (account) {
                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.claim({
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success');
                            setUpdater(new Date());
                            setCtLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                            setCtLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                            setCtLoading(false);
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please Connect to wallet !');
                setCtLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setCtLoading(false);
        }
    }

    const handleWithdrawLiquidity = async (e) => {
        e.preventDefault();
        setLocklLoading(true);
        try {

            if (account) {
                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.withdrawLiquidity({
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success');
                            setUpdater(new Date());
                            setLocklLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                            setLocklLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                            setLocklLoading(false);
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please Connect to wallet !');
                setLocklLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLocklLoading(false);
        }
    }

    const handleCancel = async (e) => {
        e.preventDefault();
        setLocklLoading(true);
        try {

            if (account) {
                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.cancel({
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success');
                            setUpdater(new Date());
                            setLocklLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                            setLocklLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                            setLocklLoading(false);
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please Connect to wallet !');
                setLocklLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLocklLoading(false);
        }
    }


    return (
        <React.Fragment>
            <section class="item-details-area" style={{ "padding": "100px 0px 0px 0px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='intro d-flex justify-content-between align-items-end m-0'>
                                <div className="intro-content">
                                    <span className="intro-text">Presale</span>
                                    <h3 className="mt-3 mb-0">Presale Details</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container mt-3">
                    <div class="row justify-content-between">
                        <div class="col-12">


                            <div class="card project-card no-hover">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-sm-5 col-xl-5">
                                            <div class="media">
                                                <img class="card-img-top avatar-max-lg" height="100" width="100px" src={social.logourl} alt="iconimage12" />
                                                <div class="media-body ml-4">
                                                    <h4 class="m-0">{stats.tokenName} Presale</h4>
                                                    <div class="countdown-times">
                                                        <h6 class="my-2">PRICE 1 {stats.currencySymbol} = {formatPrice(stats.rate)} {stats.tokenSymbol}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-5 col-xl-5 mt-md-0 mt-3 d-flex justify-content-center">
                                            <div class="countdown">
                                                <div class="text-center" style={{ "fontSize": "20px" }}>
                                                    {
                                                        parseInt(stats.endTime) < Math.floor(new Date().getTime() / 1000.0) ? 'Ended' :
                                                            parseInt(stats.startTime) > Math.floor(new Date().getTime() / 1000.0) ? 'Start In' :
                                                                parseInt(stats.startTime) < Math.floor(new Date().getTime() / 1000.0) && parseInt(stats.endTime) > Math.floor(new Date().getTime() / 1000.0) ? 'End In' : ''
                                                    }
                                                </div>
                                                <Countdown
                                                    key={Math.floor(Math.random() * 10 + 1)}
                                                    date={stats.startTime > currentTime ? stats.startTime * 1000 : stats.endTime * 1000}
                                                    renderer={countdownrender}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="card-body">
                                    <div class="items">

                                        <div class="single-item mr-2">
                                            <span>Total raise ~ </span>
                                            <span>{formatPrice(stats.totalRaised)} {stats.currencySymbol}</span>
                                        </div>

                                        <div class="single-item mr-2">
                                            <span>Softcap ~ </span>
                                            <span>{formatPrice(stats.softCap)} {stats.currencySymbol}</span>
                                        </div>

                                        <div class="single-item mr-2">
                                            <span>Hardcap ~ </span>
                                            <span>{formatPrice(stats.hardCap)} {stats.currencySymbol}</span>
                                        </div>
                                        <div class="single-item mr-2">
                                            <span>Min Alloc. ~ </span>
                                            <span>{formatPrice(stats.minContribution)} {stats.currencySymbol}</span>
                                        </div>
                                        <div class="single-item mr-2">
                                            <span>Max Alloc. ~ </span>
                                            <span>{formatPrice(stats.maxContribution)} {stats.currencySymbol}</span>
                                        </div>
                                    </div>
                                    <div class="item-progress">
                                        <div class="progress mt-4 mt-md-5">
                                            <div class="progress-bar" role="progressbar" style={{ "width": `${parseFloat(stats.percentageRaise).toFixed(2)}%` }} aria-valuenow={parseFloat(stats.percentageRaise).toFixed(2)} aria-valuemin="0" aria-valuemax="100">{parseFloat(stats.percentageRaise).toFixed(2)}%</div>
                                        </div>
                                        <div class="progress-sale d-flex justify-content-between">
                                            <span>{formatPrice(stats.totalRaised)} {stats.currencySymbol}</span>
                                            <span>Softcap ~ {formatPrice(stats.softCap)} {stats.currencySymbol}</span>
                                            <span>Hardcap ~ {formatPrice(stats.hardCap)} {stats.currencySymbol}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="project-footer mt-2 mt-md-3">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-12 col-sm-4 col-xl-6 mt-md-0 mt-1">
                                                <div class="d-flex gap-1 justify-content-center">
                                                    {
                                                        stats.poolState === '1' ? <h4 class="tag-btn text-uppercase text-center bg-green">STATUS : Completed</h4> :
                                                        stats.poolState === '2' ? <h4 class="tag-btn text-uppercase text-center bg-red">STATUS : Canceled</h4> :
                                                            parseInt(stats.endTime) < Math.floor(new Date().getTime() / 1000.0) ? <h4 class="tag-btn text-uppercase text-center bg-red">STATUS : Ended</h4> :
                                                                parseInt(stats.startTime) > Math.floor(new Date().getTime() / 1000.0) ? <h4 class="tag-btn text-uppercase text-center bg-yellow">STATUS : Incoming</h4> :
                                                                    parseInt(stats.startTime) < Math.floor(new Date().getTime() / 1000.0) && parseInt(stats.endTime) > Math.floor(new Date().getTime() / 1000.0) ? <h4 class="tag-btn text-uppercase text-center bg-green">STATUS : OnGoing</h4> : ''
                                                    }
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-4 col-xl-6 mt-md-4 mt-1">
                                                <div class="social-share ml-auto d-flex justify-content-center">
                                                    <ul class="d-flex list-unstyled">
                                                        {social.twitter &&
                                                            <li>
                                                                <a target="_blank" rel="noreferrer" href={social.twitter}>
                                                                    <i class="fab fa-twitter"></i>
                                                                </a>
                                                            </li>
                                                        }
                                                        {social.telegram &&
                                                            <li>
                                                                <a target="_blank" rel="noreferrer" href={social.telegram}>
                                                                    <i class="fab fa-telegram"></i>
                                                                </a>
                                                            </li>
                                                        }
                                                        {social.website &&
                                                            <li>
                                                                <a target="_blank" rel="noreferrer" href={social.website}>
                                                                    <i class="fas fa-globe"></i>
                                                                </a>
                                                            </li>
                                                        }
                                                        {social.discord &&
                                                            <li>
                                                                <a target="_blank" rel="noreferrer" href={social.discord}>
                                                                    <i class="fab fa-discord"></i>
                                                                </a>
                                                            </li>
                                                        }
                                                        {social.facebook &&
                                                            <li>
                                                                <a target="_blank" rel="noreferrer" href={social.facebook}>
                                                                    <i class="fab fa-facebook"></i>
                                                                </a>
                                                            </li>
                                                        }
                                                        {social.github &&
                                                            <li>
                                                                <a target="_blank" rel="noreferrer" href={social.github}>
                                                                    <i class="fab fa-github"></i>
                                                                </a>
                                                            </li>
                                                        }

                                                        {social.instagram &&
                                                            <li>
                                                                <a target="_blank" rel="noreferrer" href={social.instagram}>
                                                                    <i class="fab fa-instagram"></i>
                                                                </a>
                                                            </li>
                                                        }

                                                        {social.reddit &&
                                                            <li>
                                                                <a target="_blank" rel="noreferrer" href={social.reddit}>
                                                                    <i class="fab fa-reddit"></i>
                                                                </a>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                </div>

                                <div class="blockchain-icon">
                                <img src={supportNetwork[queryChainId] ? supportNetwork[queryChainId].image : supportNetwork[chainId] ? supportNetwork[chainId].image : supportNetwork['default'].image} alt="iconimage12" height="40" width="40" />
                                </div>
                            </div>
                        </div>
                        <section class="staking-area" style={{ "padding": "50px 0px" }}>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="card no-hover staking-card single-staking">
                                            <h3 class="m-0 mb-1">Participate</h3>
                                            <span>Balance ~ {accStats ? formatPrice(accStats.balance) : 0} {stats.currencySymbol}</span>
                                            <div class="input-box my-1">
                                                <div class="input-area d-flex flex-column flex-md-row">
                                                    <div class="input-text">
                                                        <input type="text" value={amount} onChange={(e) => { handleChangeAmount(e) }} placeholder="0.00" />
                                                        <a href="#sec" onClick={(e) => { handleMaxAmount(e) }}>Max</a>
                                                    </div>
                                                    <Button
                                                        variant="none"
                                                        disabled={btndisabled} loading={loading}
                                                        type="button"
                                                        onClick={(e) => { handleSubmitContribution(e) }}
                                                        className="btn btn-bordered-white mt-2 mt-md-0 ml-md-3">submit</Button>

                                                </div>
                                                <span className='text-danger'>
                                                    <small>{error_msg}</small>
                                                </span>
                                            </div>
                                            {(stats.poolState === '2' || stats.poolState === '0') &&
                                                <React.Fragment>
                                                    <h4 class="mt-4">Your Contribution</h4>
                                                    <span className='mt-0 mb-3'>{accStats.contributionOf ? formatPrice(accStats.contributionOf) : '0'} {stats.currencySymbol}</span>
                                                </React.Fragment>
                                            }
                                            {/* <div class="input-area d-flex flex-column flex-md-row mb-3"> */}
                                            {stats.poolState === '1' &&
                                                <React.Fragment>
                                                    <h4 class="mb-15">Your Claimble Token</h4>
                                                    <span className='mt-0 mb-3'>{accStats.userAvalibleClaim ? formatPrice(accStats.userAvalibleClaim) : '0'} {stats.tokenSymbol}</span>
                                                    <Button
                                                        loading={ctLoading}
                                                        variant="none"
                                                        className="btn input-btn mt-2 mt-md-0 mr-md-3"
                                                        onClick={(e) => handleClaimToken(e)}>Claim Token
                                                    </Button>
                                                </React.Fragment>
                                            }
                                            {accStats.contributionOf > 0 && (stats.poolState === '2' || stats.poolState === '0') &&
                                                <>
                                                    <Button
                                                        loading={wcLoading}
                                                        className="btn input-btn mt-4 mt-md-0 mr-md-3"
                                                        onClick={(e) => handleWithdrawContribution(e)}
                                                    >
                                                        Withdraw Contribution
                                                    </Button>
                                                    <small className='text-danger'>*Early withdrawal of 6% penalty</small>
                                                </>
                                            }
                                            {/* </div> */}


                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 mt-md-0 mt-2">
                                        <div class="card no-hover staking-card single-staking">
                                            <div class="d-flex justify-content-between">
                                                <h3 class="m-0">Admin Zone</h3>
                                                {stats.governance && account && stats.governance.toLowerCase() === account.toLowerCase() &&
                                                    <a href="#sec"
                                                        onClick={(e) => setEditmodalShow(!editmodalShow)}
                                                    >
                                                        <img src={editImg} className="ml-4" alt="img-edit" style={{ "height": "20px", "width": "20px" }} />
                                                    </a>
                                                }
                                            </div>
                                            {stats.governance && account && stats.governance.toLowerCase() === account.toLowerCase() ? (
                                                <React.Fragment>
                                                    <ul class="nav nav-tabs staking-tabs border-0 my-3 my-md-4 justify-content-center" role="tablist">
                                                        <li class="nav-item" role="presentation">
                                                            <button value="1" onClick={(e) => handleWhitelistStatus(e)} className={`tab-link ${stats.useWhitelisting ? 'active' : ''}`} href="#tab-one" role="tab">Whitelist</button>
                                                        </li>
                                                        <li class="nav-item" role="presentation">
                                                            <button
                                                                value="2"
                                                                onClick={(e) => handleWhitelistStatus(e)}
                                                                className={`tab-link ${stats.useWhitelisting ? '' : 'active'}`} href="#tab-two">Public
                                                            </button>
                                                        </li>
                                                    </ul>

                                                    <div class="input-box my-1">
                                                        <div class="input-area d-flex justify-content-center flex-column flex-md-row mb-3">
                                                            <button type="button" class="btn input-btn mt-2 mt-md-0 ml-md-3" onClick={(e) => setModalShow(!modalShow)}>Add users whitelist</button>
                                                            <button type="button" class="btn input-btn mt-2 mt-md-0 ml-md-3" onClick={(e) => setUnsetmodalShow(!unsetmodalShow)}>Remove whitelist users</button>
                                                        </div>
                                                    </div>
                                                    <div class="input-box my-1">
                                                        <div class="input-area d-flex justify-content-center flex-column flex-md-row mb-3">
                                                            {stats.poolState === '0' &&
                                                                <Button
                                                                    variant="none"
                                                                    loading={locklLoading}
                                                                    onClick={(e) => handleCancel(e)}
                                                                    type="button"
                                                                    class="btn btn-bordered-white mt-2 mt-md-0 ml-md-3">cancel</Button>
                                                            }
                                                            {stats.poolState === '0' && (stats.totalRaised === stats.hardCap || parseFloat(stats.hardCap - stats.totalRaised) < parseFloat(stats.minContribution) ||
                                                                stats.totalRaised >= stats.softCap)  && Math.floor(new Date().getTime() / 1000.0) >= stats.endTime && 
                                                                <Button
                                                                    variant="none"
                                                                    type="button"
                                                                    loading={finalLoading}
                                                                    onClick={(e) => handleFinalize(e)}
                                                                    class="btn btn-bordered-white mt-2 mt-md-0 ml-md-3">Finalize
                                                                </Button>
                                                            }
                                                            {stats.poolState === '1' && stats.liquidityUnlockTime <= Math.floor(new Date().getTime() / 1000.0) &&
                                                                <Button
                                                                    type="button"
                                                                    loading={locklLoading}
                                                                    onClick={(e) => handleWithdrawLiquidity(e)}
                                                                    class="btn btn-bordered-white mt-2 mt-md-0 ml-md-3">
                                                                    Withdraw Liquidity
                                                                </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <div class="d-flex justify-content-center">
                                                    <h5 class="mt-5">You are not onwer of pool</h5>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="staking-area" style={{ "padding": "0px 0px 50px 0px" }}>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 col-md-6 mb-3">
                                        <div class="card no-hover staking-card single-staking">
                                            <h3 class="m-0 mb-3">Sale Info</h3>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Sale Type</span>
                                                <span>Presale</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Min. Allocation</span>
                                                <span>{formatPrice(stats.minContribution)} {stats.currencySymbol}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Max. Allocation</span>
                                                <span>{formatPrice(stats.maxContribution)} {stats.currencySymbol}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Token Price</span>
                                                <span>1 {stats.currencySymbol} = {formatPrice(stats.rate)} {stats.tokenSymbol}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Access type</span>
                                                <span>{stats.useWhitelisting ? 'Whitelist' : 'Public'}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 mt-md-0 mt-2 mb-3">
                                        <div class="card no-hover staking-card single-staking">
                                            <h3 class="m-0 mb-3">Token Info</h3>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Token Name</span>
                                                <span>{stats.tokenName}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Token Symbol</span>
                                                <span>{stats.tokenSymbol}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Decimals</span>
                                                <span>{stats.tokenDecimal}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Address</span>
                                                <span>
                                                    {stats.token.toString().slice(0, 15)}.....
                                                    <CopyToClipboard text={stats.token} onCopy={() => {
                                                        setRefcopy(true);
                                                        setTimeout(() => {
                                                            setRefcopy(false);
                                                        }, 2000)
                                                    }}>
                                                        <img src={iconImg} alt="project" />
                                                    </CopyToClipboard>
                                                    <span>{refcopy && 'copied'}</span>
                                                </span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Total Supply</span>
                                                <span>{formatPrice(stats.tokenSupply)} {stats.tokenSymbol}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="col-12 col-lg-12 items mb-3">
                            <div class="card project-card single-item-content no-hover item">
                                <h3 class="m-0 mb-4">Project Summary</h3>
                                <div className='mb-5' style={{ "whiteSpace": "pre-wrap" }}>{social.brief ? social.brief : ''}</div>
                            </div>
                        </div>
                        <section class="staking-area" style={{ "padding": "30px 0px 50px 0px" }}>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 col-md-6 mb-4">
                                        <div class="card no-hover staking-card single-staking">
                                            <h3 class="m-0 mb-3">Other Info</h3>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Listing Rate</span>
                                                <span>1 {stats.currencySymbol} ~ {formatPrice(stats.liquidityListingRate)} {stats.tokenSymbol}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Unsold Tokens </span>
                                                <span>{stats.refundType === '0' ? 'Refund' : 'Burn'}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Listing On </span>
                                                <span>{contract[queryChainId] ? contract[queryChainId].routername : contract[chainId] ? contract[chainId].routername : contract['default'].routername}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Liquidity Percent</span>
                                                <span>{stats.liquidityPercent} %</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>Liquidity Unlocked Time(After Sale Over)</span>
                                                <span>{parseFloat(stats.liquidityLockDays) / 60} minutes</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-1">
                                                <span>First Release For Presale</span>
                                                <span>{stats.tgeBps > 0 ? stats.tgeBps / 100 : 100} %</span>
                                            </div>
                                            {stats.cycle > 0 &&
                                                <div class="d-flex justify-content-between mb-1">
                                                    <span>Tokens release each cycle</span>
                                                    <span>{stats.cycleBps / 100}% each  {formatPrice(stats.cycle / 60)} minutes</span>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 mt-md-0 mb-3">
                                        {/* <div class="card no-hover staking-card single-staking"> */}
                                        {social.youtube &&
                                            <div className="project-video-area" >
                                                <ReactPlayer style={{
                                                    "maxWidth": "100%",
                                                    "height": "auto"
                                                }} controls={true} loop={false} url={social.youtube} />
                                                {/* <img src="../assets/images/project/project-video.jpg" alt="ProjectImage12" />
                                                <a className="video-icons" href={social.youtube}><i className="icon-play"></i></a> */}
                                            </div>
                                        }

                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>


            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add user to whitelist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Control as="textarea" onChange={(e) => { setWhitelistAddress(e.target.value) }} rows={8} placeholder="Insert address: separate with breaks line.
            Ex:
            0x34E7f6A4d0BB1fa7aFe548582c47Df337FC337E6
            0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1
            0x968136BB860D9534aF1563a7c7BdDa02B1A979C2" value={whitelistAddress} />
                        </Form.Group>
                        <Button variant="none" className="btn btn-success" loading={waddloading} onClick={(e) => { handleSetWhitelist(e) }}>Submit</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                show={unsetmodalShow}
                onHide={() => setUnsetmodalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Remove user from whitelist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Control as="textarea" onChange={(e) => { setRemoveWhitelistAddress(e.target.value) }} rows={8} placeholder="Insert address: separate with breaks line.
            Ex:
            0x34E7f6A4d0BB1fa7aFe548582c47Df337FC337E6
            0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1
            0x968136BB860D9534aF1563a7c7BdDa02B1A979C2" value={removeWhitelistAddress} />
                        </Form.Group>
                        <Button variant="none" className="btn btn-success" loading={waddloading} onClick={(e) => { handleRemoveWhitelist(e) }}>Submit</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                show={editmodalShow}
                onHide={() => setEditmodalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Details</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Logo URL<span className='text-danger'>*</span></label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.logourl} name="logourl" placeholder="e.g. https://blockstar.site/pwa_image/blockstar_pwa.png" />
                                    <small className='text-danger'>{error.logourl}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Website*<span className='text-danger'>*</span></label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.website} name="website" placeholder="e.g. https://blockstar.site" />
                                    <small className='text-danger'>{error.website}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Facebook</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.facebook} name="facebook" placeholder="e.g. https://www.facebook.com/" />
                                    <small className='text-danger'>{error.facebook}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Twitter</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.twitter} name="twitter" placeholder="e.g. https://twitter.com/" />
                                    <small className='text-danger'>{error.twitter}</small><br />
                                </div>
                            </div>

                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Github</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.github} name="github" placeholder="e.g. https://github.com/" />
                                    <small className='text-danger'>{error.github}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Telegram</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" name="telegram" value={editSocial.telegram} placeholder="e.g. https://t.me/BlockStar_Social_Media" />
                                    <small className='text-danger'>{error.telegram}</small><br />
                                </div>
                            </div>

                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Instagram</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" name="instagram" value={editSocial.instagram} placeholder="e.g. https://www.instagram.com/" />
                                    <small className='text-danger'>{error.instagram}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Discord</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" name="discord" value={editSocial.discord} placeholder="e.g. https://discord.com/" />
                                    <small className='text-danger'>{error.discord}</small><br />
                                </div>
                            </div>

                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Reddit</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" name="reddit" value={editSocial.reddit} placeholder="e.g. https://reddit.com/" />
                                    <small className='text-danger'>{error.reddit}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Youtube Video</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" name="youtube" value={editSocial.youtube} placeholder="e.g. https://www.youtube.com/watch?v=BHACKCNDMW8" />
                                    <small className='text-danger'>{error.youtube}</small><br />
                                </div>
                            </div>

                            <div className="col-md-12 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea type="text" style={{ "height": "167px" }} name="brief" onChange={(e) => onChangeInput(e)} className="brief" placeholder="Project Brief" >{editSocial.brief}</textarea>
                                    <small className='text-danger'>{error.brief}</small><br />
                                </div>
                            </div>

                            <div className="col-md-12 mt-4 mb-0 d-flex justify-content-center">
                                <Button className="btn btn-success" loading={editLoading} onClick={(e) => handleEditSubmit(e)}>submit</Button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment >
    )
}
