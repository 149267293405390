import bankx from '../images/par1.jpg';
import dexTrade from '../images/par2.jpg';
import lotto from '../images/par3.jpg';
import oxfactory from '../images/par4.jpg';


export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}


//Launchpad Contract

export const contract = {
  // 7622: {
  //   poolfactory: "0x1F93D2d3dB35AbB69a2e86541aBC959D29dfFd58",
  //   poolmanager: "0xa7D9E677b35ad1547379101D5c3bC22e452b6029",
  //   routeraddress: "0xD6cE1C02b23a49A75825EE55EAC4EBA706EC9bC9",
  //   multicallAddress: "0x83cF25F44500F7D61c5B82a69922F4B5Bde9D8C8",
  //   lockAddress: "0x4AD17d3Ba3caC8C6b5d989AF965f5f8c5412B5f7",
  //   routername: "KronoSwapRouter"
  // },
  13600 : {
    poolfactory: "0xE7655F0869d636dd83265ECD3e51f5d58617A6F0",
    poolmanager: "0xFfBCa1341003F6195896c212AB374Dec6261F346",
    routeraddress: "0x0852963f717249B7E27fFEd8D6f8E619B026F84e",
    multicallAddress: "0xAd08c500E7b180E4f762E2aAeB2d4D9803a2273e",
    lockAddress: "0x917a2e64bE3ADAF3bca88D5de3FF0af0A3e5F6E5",
    routername: "KronoSwapRouter"
  },
  // 97 : {
  //   poolfactory: "0xB67e2f6f0932Af4fA9bA26f5E71e7Ed89FA36d90",
  //   poolmanager: "0x85C380345703EAb9159A9B21CA2fE9A4cAC7978b",
  //   routeraddress: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
  //   multicallAddress: "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18",
  //   lockAddress: "0x34520Ed72AFf7f3D6F1CE1027275E7EBbaBde42d",
  //   routername: "Pancakeswap"
  // },
  'default': {
    poolfactory: "0xE7655F0869d636dd83265ECD3e51f5d58617A6F0",
    poolmanager: "0xFfBCa1341003F6195896c212AB374Dec6261F346",
    routeraddress: "0x0852963f717249B7E27fFEd8D6f8E619B026F84e",
    multicallAddress: "0xAd08c500E7b180E4f762E2aAeB2d4D9803a2273e",
    lockAddress: "0x917a2e64bE3ADAF3bca88D5de3FF0af0A3e5F6E5",
    routername: "KronoSwapRouter"
  }

}


export const exchange_partneters = [
  {
    img: bankx,
    link: 'hhttps://dextrade.com/'
  },
  {
    img: dexTrade,
    link: 'https://nonkyc.io'
  },
  {
    img: lotto,
    link: 'https://www.cryptolotterynft.com/'
  },
  {
    img: oxfactory,
    link: 'https://0xfactory.com/'
  }
]

export const FOOTER_SOCIAL = [
  {
    icon: 'fa-brands fa-telegram',
    link: 'https://t.me/kronobit'
  },
  {
    icon: 'fa-brands fa-twitter',
    link: 'https://twitter.com/Kronobit_Knb'
  },
  {
    icon: 'fa-brands fa-instagram',
    link: 'https://www.instagram.com/kronobit/'
  },
  {
    icon: 'fa-brands fa-medium',
    link: 'https://kronobitnetworks.medium.com/'
  },
  {
    icon: 'fa-brands fa-discord',
    link: 'https://discord.com/invite/gEpTj38z'
  },
  {
    icon: 'fa-brands fa-youtube',
    link: 'https://www.youtube.com/@kronobit_networks'
  },
]



