import React, { useEffect, useState } from 'react'
import { useAdminStats } from '../hooks/useState';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import { contract } from '../hooks/constant';
import poolFactoryAbi from '../json/poolfactory.json';
import { getContract } from '../hooks/contractHelper';
import { ethers } from 'ethers';
import { getWeb3 } from '../hooks/connectors';

export default function Admin() {
    const { account, chainId, library } = useWeb3React()
    const [isEnabled, setIsEnabled] = useState(false);
    const [adminwallet, setAdminwallet] = useState('');
    const [wfees, setWfees] = useState(0);
    const [presalefees, setPresalefees] = useState(0);
    const [privatefees, setPrivatefees] = useState(0);
    const [fairfees, setFairfees] = useState(0);
    const [owner, setOwner] = useState('');
    const [mowner, setMOwner] = useState('');
    const [updater] = useState(1);
    const stats = useAdminStats(updater)

    useEffect(() => {
        setIsEnabled(stats.isEnabled);
        setAdminwallet(stats.adminWallet);
        setWfees(stats.contributeWithdrawFee);
        setPresalefees(stats.presalePrice);
        setPrivatefees(stats.privatePrice);
        setFairfees(stats.fairPrice);
        setOwner(stats.owner);
        setMOwner(stats.mowner);
    }, [stats]);




    const handleSubmit = async (id) => {
        if (account) {
            if (chainId) {
                if (!id) {
                    toast.error('something went wrong');
                    return false;
                }
                try {
                    let tx;
                    let poolfactoryAddress = contract[chainId] ? contract[chainId].poolfactory : contract['default'].poolfactory;
                    let poolManagerAddress = contract[chainId] ? contract[chainId].poolmanager : contract['default'].poolmanager
                    let factoryContract = getContract(poolFactoryAbi, poolfactoryAddress, library);
                    let managerContract = getContract(poolFactoryAbi, poolManagerAddress, library);
                    if (id === 1) {
                        tx = await factoryContract.setIsEnabled(isEnabled, {
                            from: account
                        })
                    }
                    else if (id === 2) {
                        tx = await factoryContract.setAdminWallet(adminwallet, {
                            from: account
                        })
                    }
                    else if (id === 3) {
                        let fees = wfees * 100;
                        tx = await factoryContract.setcontributeWithdrawFee(fees.toString(), {
                            from: account
                        })
                    }
                    else if (id === 4) {
                        let fees = ethers.utils.parseUnits(presalefees.toString());
                        tx = await factoryContract.setPresalePoolPrice(fees, {
                            from: account
                        })
                    }
                    else if (id === 5) {
                        let fees = ethers.utils.parseUnits(privatefees.toString());
                        tx = await factoryContract.setPrivatePoolPrice(fees, {
                            from: account
                        })
                    }
                    else if (id === 6) {
                        let fees = ethers.utils.parseUnits(fairfees.toString());
                        tx = await factoryContract.setFairPoolPrice(fees, {
                            from: account
                        })
                    }
                    else if (id === 7) {
                        tx = await factoryContract.transferOwnership(owner, {
                            from: account
                        })
                    }
                    else if (id === 8) {
                        tx = await managerContract.transferOwnership(mowner, {
                            from: account
                        })
                    }
                    else {
                        return false;
                    }


                    toast.loading('Waiting for confirmation...');
                    let web3 = getWeb3(chainId);
                    var interval = setInterval(async function () {

                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    toast.dismiss();
                    toast.error(err.reason ? err.reason : err.message);
                }
            }
            else {
                toast.error('please switch network to krono mainnet chain');
            }
        }
        else {
            toast.error('please connect wallet');
        }

    }

    return (
        <div className="main">
            <section className="hero-section">
                <div className="container">
                    <table class="table table-striped table-dark">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Function Name</th>
                                <th scope="col">Input</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>1</th>
                                <td>Is Enabled</td>
                                <td>
                                    <select onChange={(e) => setIsEnabled(e.target.value)} class="custom-select">
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </td>
                                <td>
                                    <button type="button" onClick={() => handleSubmit(1)} class="btn btn-primary">Submit</button>
                                </td>
                            </tr>

                            <tr>
                                <th>2</th>
                                <td>Set Admin Wallet</td>
                                <td>
                                    <div class="form-group">
                                        <label>Admin address</label>
                                        <input type="text" onChange={(e) => setAdminwallet(e.target.value)} value={adminwallet} class="form-control" placeholder="" />
                                    </div>
                                </td>
                                <td>
                                    <button type="button" onClick={() => handleSubmit(2)} class="btn btn-primary">Submit</button>
                                </td>
                            </tr>

                            <tr>
                                <th>3</th>
                                <td>contribute Withdraw Fee</td>
                                <td>
                                    <div class="form-group">
                                        <label>Set Fees(%)</label>
                                        <input type="text" onChange={(e) => setWfees(e.target.value)} value={wfees} class="form-control" placeholder="" />
                                    </div>
                                </td>
                                <td>
                                    <button type="button" onClick={() => handleSubmit(3)} class="btn btn-primary">Submit</button>
                                </td>
                            </tr>

                            <tr>
                                <th>4</th>
                                <td>Presale Create Fee</td>
                                <td>
                                    <div class="form-group">
                                        <label>Set Fees(KNB)</label>
                                        <input type="text" onChange={(e) => setPresalefees(e.target.value)} value={presalefees} class="form-control" placeholder="" />
                                    </div>
                                </td>
                                <td>
                                    <button type="button" onClick={() => handleSubmit(4)} class="btn btn-primary">Submit</button>
                                </td>
                            </tr>

                            <tr>
                                <th>5</th>
                                <td>PrivateSale Create Fee</td>
                                <td>
                                    <div class="form-group">
                                        <label>Set Fees(KNB)</label>
                                        <input type="text" onChange={(e) => setPrivatefees(e.target.value)} value={privatefees} class="form-control" placeholder="" />
                                    </div>
                                </td>
                                <td>
                                    <button type="button" onClick={() => handleSubmit(5)} class="btn btn-primary">Submit</button>
                                </td>
                            </tr>

                            <tr>
                                <th>6</th>
                                <td>FairSale Create Fee</td>
                                <td>
                                    <div class="form-group">
                                        <label>Set Fees(KNB)</label>
                                        <input type="text" onChange={(e) => setFairfees(e.target.value)} value={fairfees} class="form-control" placeholder="" />
                                    </div>
                                </td>
                                <td>
                                    <button type="button" onClick={() => handleSubmit(6)} class="btn btn-primary">Submit</button>
                                </td>
                            </tr>
                            <tr>
                                <th>7</th>
                                <td>Factory Transfer Ownership</td>
                                <td>
                                    <div class="form-group">
                                        <label>New Address</label>
                                        <input type="text" onChange={(e) => setOwner(e.target.value)} value={owner} class="form-control" placeholder="" />
                                    </div>
                                </td>
                                <td>
                                    <button type="button" onClick={() => handleSubmit(7)} class="btn btn-primary">Submit</button>
                                </td>
                            </tr>
                            <tr>
                                <th>8</th>
                                <td>Manager Transfer Ownership</td>
                                <td>
                                    <div class="form-group">
                                        <label>New Address</label>
                                        <input type="text" onChange={(e) => setMOwner(e.target.value)} value={mowner} class="form-control" placeholder="" />
                                    </div>
                                </td>
                                <td>
                                    <button type="button" onClick={() => handleSubmit(8)} class="btn btn-primary">Submit</button>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    )
}
