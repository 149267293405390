import React, { useMemo } from 'react';
import {
    Link,
    useParams,
    useLocation,
} from "react-router-dom";
import Connect from './Connect';
import queryString from "query-string";
import logoImg from '../images/logo.png'
// import { useTopPoolState } from '../pages/launchpadApply/SaleList/helper/useStats';


export default function Header() {
    const router = useRouter();
    // const poolstats = useTopPoolState(1);

    return (
        <React.Fragment>


            <div id="gameon-preloader" className="gameon-preloader">
                <div className="preloader-animation">
                    <div className="spinner"></div>
                    <p className="fw-5 text-center text-uppercase">Loading</p>
                </div>

                <div className="loader-animation">
                    <div className="row h-100">

                        <div className="col-3 single-loader p-0">
                            <div className="loader-bg"></div>
                        </div>

                        <div className="col-3 single-loader p-0">
                            <div className="loader-bg"></div>
                        </div>

                        <div className="col-3 single-loader p-0">
                            <div className="loader-bg"></div>
                        </div>

                        <div className="col-3 single-loader p-0">
                            <div className="loader-bg"></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="main">

                <header id="header">

                    <nav data-aos="zoom-out" data-aos-delay="800" className="navbar gameon-navbar navbar-expand">
                        <div className="container header">
                            <Link className="navbar-brand" to="/">
                                <img src={logoImg} alt="Brand Logo" />
                            </Link>

                            <div className="ml-auto"></div>
                            <ul className="navbar-nav items mx-auto">

                                <li className="nav-item">
                                    <Link to="/" className={`nav-link  ${router.pathname === "/" ? "active" : ""}`}>Home</Link>
                                </li>

                                <li class="nav-item dropdown">
                                    <a href="#menu" class={`nav-link ${router.pathname === "/presale" || router.pathname === "/privatesale" || router.pathname === "/fairlaunch" ? 'active' : ''}`}>Create <i class="icon-arrow-down"></i></a>
                                    <ul class="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/presale" className={`nav-link  ${router.pathname === "/presale" ? "active" : ""}`}>Create Presale</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/privatesale" className={`nav-link  ${router.pathname === "/privatesale" ? "active" : ""}`}>Create PrivateSale</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/fairlaunch" className={`nav-link  ${router.pathname === "/fairlaunch" ? "active" : ""}`}>Create fairlaunch</Link>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item dropdown">
                                    <a href="#menu" class={`nav-link ${router.pathname === "/lock" || router.pathname === "/token-locked" || router.pathname === "/liquidity-locked" ? 'active' : ''}`}>Lock <i class="icon-arrow-down"></i></a>
                                    <ul class="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/lock" className={`nav-link  ${router.pathname === "/lock" ? "active" : ""}`}>Create</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/knb-locked" className={`nav-link  ${router.pathname === "/knb-locked" ? "active" : ""}`}>KNB Lock </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/token-locked" className={`nav-link  ${router.pathname === "/token-locked" ? "active" : ""}`}>Token Lock </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/liquidity-locked" className={`nav-link  ${router.pathname === "/liquidity-locked" ? "active" : ""}`}>Liquidity Lock</Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="/sale-list" className={`nav-link  ${router.pathname === "/sale-list" || router.pathname === '/my-contribution' ? "active" : ""}`}>launchpads</Link>
                                </li>
                                <li className="nav-item">
                                    <a href="https://kronoswap.com/" target='_blank' rel="noreferrer" className={`nav-link`}>kronoswap</a>
                                </li>
                            </ul>


                            <ul className="navbar-nav toggle">
                                <li className="nav-item">
                                    <a href="#sec" className="nav-link" data-toggle="modal" data-target="#menu">
                                        <i className="icon-menu m-0"></i>
                                    </a>
                                </li>
                            </ul>


                            <ul className="navbar-nav action">
                                <Connect />
                            </ul>

                        </div>

                    </nav>
                    {/* <div class="dex-section" style={{ "marginTop": "80px" }}>
                        <div class="container-fluid mt-3">
                            <div class="row items-center">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="header-hot-pairs">
                                        <div class="left-container">
                                            <h4 class="hot-pair">
                                                <fa-icon icon="fire" class="ng-fa-icon mr-1">
                                                    <i class="fas fa-fire"></i>
                                                </fa-icon>
                                                <span class="">TRENDING</span>
                                            </h4>
                                        </div>
                                        <div class="right-container">
                                            <ul class="hot-pairs" id="webticker-1">
                                                {poolstats.topPools.length > 0 && poolstats.topPools.slice(0).reverse().map((rowdata, index) => {

                                                    return (

                                                        rowdata.startTime > 0 &&
                                                        <li class="" ket={index}>
                                                            <a class="ml-3 py-2 hot-pair first" href={rowdata.poolType === '0' ? `/presale-details/${rowdata.poolAddress}${`?chainid=${poolstats.chainId}`}` : rowdata.poolType === '1' ? `/private-details/${rowdata.poolAddress}${`?chainid=${poolstats.chainId}`}` : rowdata.poolType === '2' ? `/fairlaunch-details/${rowdata.poolAddress}?chainid=${poolstats.chainId}` : '/'}>
                                                                <span>#{index + 1}</span>
                                                                {rowdata.symbol ? rowdata.symbol : ''}
                                                                <img class="hot-exchange" alt="uniswap-logo" src={rowdata.poolDetails.split('$#$')[0]} />
                                                            </a>
                                                        </li>

                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </header>


                <div id="menu" className="modal fade p-0">
                    <div className="modal-dialog dialog-animated">
                        <div className="modal-content h-100">
                            <div className="modal-header" data-dismiss="modal">
                                Menu <i className="far fa-times-circle icon-close"></i>
                            </div>
                            <div className="menu modal-body">
                                <div className="row w-100">
                                    <div className="items p-0 col-12 text-center"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}


export function useRouter() {
    const params = useParams();
    const location = useLocation();

    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            // For convenience add push(), replace(), pathname at top level
            push: location.push,
            replace: location.replace,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: {
                ...queryString.parse(location.search), // Convert string to object
                ...params,
            },
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            location,

        };
    }, [params, location]);
}
